import { ReactElement } from "react";
import { Flex, FlexProps, IconProps, Text, TextProps } from "@chakra-ui/react";
import { IconHidden } from "../icons";

const EMPTY_STATE_DESCRIPTION_TEST_ID = "empty-state-description";
const EMPTY_STATE_TITLE_TEST_ID = "empty-state-title";

export interface IEmptyStateProps extends FlexProps {
    title: string;
    description?: string;
    iconSize?: IconProps["boxSize"];
    CustomIcon?: ReactElement;
    descriptionProps?: TextProps;
}
export const EmptyState = (props: IEmptyStateProps) => {
    const { title, description, iconSize = [10, "136px"], CustomIcon, descriptionProps = {}, ...flexProps } = props;

    return (
        <Flex direction="column" align="center" p={[2, 5]} pb={[3, 5]} bg="white" rounded="8px" boxShadow="sm" {...flexProps}>
            {CustomIcon ? CustomIcon : <IconHidden boxSize={iconSize} />}
            <Text fontSize="xl" fontWeight="bold" textAlign="center" mt={[2, 3]} data-test-id={EMPTY_STATE_TITLE_TEST_ID}>
                {title}
            </Text>
            {description && (
                <Text {...descriptionProps} textAlign="center" mt={2} data-test-id={EMPTY_STATE_DESCRIPTION_TEST_ID}>
                    {description}
                </Text>
            )}
        </Flex>
    );
};
